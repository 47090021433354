@import url(https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300;400;600&family=Source+Sans+3:wght@300;400;600&display=swap);
html,
body {
  /* background-color: #F7F6F4 !important; */
  padding: 0;
  margin: 0;
  font-family: 'Albert Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
}

main {
  padding: 5rem 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-family: Menlo, Monaco, Lucida Console, Courier New, monospace;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.home-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  background: #F2F2F2;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.select-date {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.custom-date-picker {
  text-align: right !important;
  width: 100px !important;
  padding: 0 10px !important;
}

.filters-switch-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.filters-switch {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: row-reverse;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #32BCB0;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.filters-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 0.75rem;
}

.filter-label {
  margin-right: 0.5rem;
}

.date-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: scroll;
  max-height: 500px;
}

@media screen and (max-width: 768px) {
  .table-responsive {
    width: 95vw;
    height: 80vh;
    margin-top: 0.75rem;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dee2e6;
  }
  .table-responsive::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .table-responsive::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.download-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;
}

.download-button {
  color: white;
  background: #32BCB0;
  border: 1px solid #32BCB0;
  border-radius: 5px;
  margin: 5px;
  padding: 1px 10px;
  /* border: 1px solid; */
  /* border-radius: 5px; */
}

.login-button {
  color: white !important;
  background: #32BCB0 !important;
  border: 1px solid #32BCB0 !important;
  border-radius: 5px !important;
  margin: 5px !important;
  padding: 1px 10px !important;
  /* border: 1px solid; */
  /* border-radius: 5px; */
}

.logout-button {
  color: gray !important;
  background: #F2F2F2 !important;
  border: 1px solid #F3F3F3 !important;
  border-radius: 5px !important;
  margin: 5px !important;
  padding: 1px 10px !important;
  /* border: 1px solid; */
  /* border-radius: 5px; */
}

.chart-container {
  width: 512px;
}

@media screen and (max-width: 512px) {
  .chart-container {
    position: relative;
    width: 95vw;
    height: auto;
    margin: 20px auto;
  }
}

.nav-link {
  color: #4f4f4f !important; 
}

.active {
  color: #32BCB0 !important; 
}

.admin-section {
  background: #F2F2F2;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.admin-list-container {
  max-width: 400px;
  overflow-x: auto;
}

.admin-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.remove-admin-button {
  color: #F26C74;
  background: transparent;
  border: none;
  outline: none;
}

.add-admin-button {
  color: #32BCB0;
  background: transparent;
  border: none;
  margin: 5px;
  /* border: 1px solid; */
  /* border-radius: 5px; */
}

.new-admin-form {
  display: flex;
  justify-content: space-between;
  /* width: 340px; */
  /* overflow-x: auto; */
}

.new-admin-input {
  border: 1px solid #4f4f4f;
  border-radius: 5px;
  margin: 5px 0;
  padding: 0 5px;
}

