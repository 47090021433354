@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300;400;600&family=Source+Sans+3:wght@300;400;600&display=swap');

html,
body {
  /* background-color: #F7F6F4 !important; */
  padding: 0;
  margin: 0;
  font-family: 'Albert Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
}

main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-family: Menlo, Monaco, Lucida Console, Courier New, monospace;
}
